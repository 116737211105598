import { db, updateFirebase, auth } from "./setup";

export async function bingoSing(callback, bingoSingData, userData) {
  console.log("🚀 ~ file: api.js:4 ~ bingoSing ~ bingoSingData:", bingoSingData)
  return await auth.onAuthStateChanged(async (user) => {
    if (user) {
      bingoSingData.userId = user.uid;
      bingoSingData.serverTimestamp = updateFirebase.serverTimestamp();
      var batch = db.batch();
      var bingoSingRef = db
        .collection("bingoSing")
        .doc(`${user.uid}${bingoSingData.numberCard}`);
      var userRef = db.collection("users").doc(user.uid);
      batch.set(bingoSingRef, bingoSingData);
      batch.update(userRef, {
        winners: userData.winners,
        attempts: userData.attempts,
      });

      return await batch
        .commit()
        .then((docRef) => {
          callback(true);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          callback(false);
        });
    } else {
      callback(false);
    }
  });
}
export async function updateUser(callback, data) {
  const unsub = auth.onAuthStateChanged((user) => {
    if (user && !user.isAnonymous) {
      db.collection("users")
        .doc(user.uid)
        .update({ winners: data })
        .then((docRef) => {
          callback(true);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          callback(false);
        });
    }
  });
  return unsub;
}

export async function login(data, callback) {
  console.log(data.email, data.password);
  auth
    .signInWithEmailAndPassword(data.email, data.password)
    .then(async (e) => {
      callback(true);
    })
    .catch(async (error) => {
      console.log("login -> error", error);
      callback(false);
    });
}

let generarCartones = (numberOfCartons) => {
  const lista = [
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
    [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75],
  ];
  let bingo = [];
  for (var bi = 0; bi < numberOfCartons; bi++) {
    bingo.push({
      B: [],
      I: [],
      N: [],
      G: [],
      O: [],
    });

    let letra = "";

    for (var i = 0; i < 5; i++) {
      switch (i) {
        case 0:
          letra = "B";
          break;
        case 1:
          letra = "I";
          break;
        case 2:
          letra = "N";
          break;
        case 3:
          letra = "G";
          break;
        default:
          letra = "O";
      }

      let result = lista[i].sort(function () {
        return Math.random() - 0.5;
      });

      bingo[bi][letra] = result.slice(0, i === 2 ? 4 : 5);
    }
  }

  return bingo;
};

export async function createUser(data, callback) {
  await auth
    .createUserWithEmailAndPassword(data.email, data.password)
    .then(async (user) => {
      delete data.password;
      //------------------ Guardando en base de datos
      let invitedId = data.invitedId;

      data.bingo = generarCartones(data.numberOfCartons);

      const batch = db.batch();

      const usersRef = db.collection("users").doc(user.user.uid);

      const accessListRef = db.collection("accessList").doc(`${invitedId}`);

      batch.set(usersRef, data);

      batch.update(accessListRef, { count: updateFirebase.increment(1) });

      return await batch
        .commit()
        .then((docRef) => {
          callback(true);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          callback(false);
        });
      //------------------ Guardando en base de datos
    })
    .catch(async (error) => {
      console.log("🚀 ~ file: api.js ~ line 148 ~ createUser ~ error", error);
      if (error.code === "auth/email-already-in-use") {
        await auth
          .signInWithEmailAndPassword(data.email, data.password)
          .then(async (e) => {
            delete data.password;
            //------------------ Guardando en base de datos
            let invitedId = data.invitedId;

            data.bingo = generarCartones(data.numberOfCartons);

            const batch = db.batch();

            const usersRef = db.collection("users").doc(e.user.uid);

            const accessListRef = db
              .collection("accessList")
              .doc(`${invitedId}`);

            batch.set(usersRef, data);

            batch.update(accessListRef, { count: updateFirebase.increment(1) });

            return await batch
              .commit()
              .then((docRef) => {
                callback(true);
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
                callback(false);
              });
          })
          .catch(async (error) => {
            console.log("login -> error", error);
            callback(false);
          });
        console.log("createUser -> error", error);
      }
    });
}
